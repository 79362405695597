.flipCard {
  background-color: transparent;
  width: 100%;
  height: 200px;
  /* perspective: 1000px; */
}
.categoryCardInActive {
  background-color: transparent !important;
  height: 100%;
  display: flex;
  justify-content: center;
  border-radius: 20px !important;
  align-items: center;
  /* padding: 60px 10px; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px !important;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
}

.flipCard:hover .flipCardInner {
  transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
  position: absolute;
  border-radius: 20px !important;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-image: url(Assets/awards/MaskGroup.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.flipCardFront {
  color: black;
  /* background-color: rgba(0,0,0,0.1); */
}

.flipCardBack {
  color: white;
  transform: rotateY(180deg);
  /* background-color: rgba(0,255,0,0.1); */
}
.h3text {
  color: black;
  font-size: 28px;
  font-style: normal;
  font-weight: bold;
  line-height: 26px;
  margin-bottom: 20px;
}
.ptext {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 31px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.54);
}

/* styles for RewardMoney.js  refactor later */

.wrapper_animation_grid {
  position: relative;
  width: 100%;
  height: 280px;
  perspective: 1000px;
  color: white;
  font-weight: 800;
  background-color: #ffffff;
  overflow: hidden;
}

.wrapper_animation_grid:hover  {
  background-color: var(--overlayColor);
}

.overlayDark,
.overlayMedium,
.overlayLight {
  /* background: rgb(0 37 49 / 91%); */
  transform: translateX(100%);
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  opacity: 1;
  background-color: #ffffff;
}

.wrapper_animation_grid:hover .overlayDark,
.wrapper_animation_grid:hover .overlayMedium,
.wrapper_animation_grid:hover .overlayLight {
  transition: all 0.4s ease-in-out;
  background-color: var(--overlayColor);
  transform: translateX(0%);
  opacity: 0;
  visibility: hidden;
}

.rewardPrize {
  color: white;
  font-weight: 800;
  font-size: 28px;
  transform: translateX(101%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.rewardAmountGrid {
  color: #042a30;
  transition: all 0.4s ease-in-out;
  font-size: 28px;
}

.wrapper_animation_grid:hover .rewardAmountGrid {
  transform: translateX(101%);  
  opacity: 0;
  visibility: hidden;
}

.wrapper_animation_grid:hover .rewardPrize {
  transform: translateX(0%);
  opacity: 1;
  visibility: visible;
}

.wrapper_animation_grid > div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-image: url(Assets/awards/bgGrid.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  display: flex;
  align-items: center;
  justify-content: center;
}
